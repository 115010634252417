import { Chain } from 'wagmi';
import { Connector } from './BaseConnector';
import { FortmaticConnector as FC } from '@web3-react/fortmatic-connector';
import {
  Address,
  SwitchChainError,
  createWalletClient,
  custom,
  UserRejectedRequestError,
  ProviderRpcError,
  getAddress,
  createPublicClient,
  http,
  HttpTransport,
  CustomTransport,
} from 'viem';

interface ConnectorUpdate<T = number | string> {
  provider?: any;
  chainId?: T;
  account?: null | string;
}

type FortmaticOptions = {
  apiKey: string;
};

export class FortmaticConnector extends Connector<
  ReturnType<typeof createPublicClient<HttpTransport, Chain>>,
  FortmaticOptions
> {
  readonly id = 'fortmatic';
  readonly name = 'Fortmatic Wallet';
  readonly ready = true;

  #fc?: FC;
  #activated?: ConnectorUpdate<string | number>;
  #provider?: ReturnType<typeof createPublicClient<HttpTransport, Chain>>;
  #walletClient?: ReturnType<
    typeof createWalletClient<CustomTransport, Chain, Address>
  >;
  #apiKey: string;

  constructor(config: { chains?: Chain[]; options: FortmaticOptions }) {
    super(config);
    this.#apiKey = config.options.apiKey;
  }

  private async _setFc(chainId: number) {
    const chain = this.chains.find((c) => c.id === chainId);
    if (!chain) return;
    this.#fc = new FC({
      apiKey: this.#apiKey,
      chainId: chainId || 1,
    });
    this.#activated = await this.#fc.activate();
    this.#provider = createPublicClient({
      chain: chain,
      transport: http(this.#activated.provider),
    });
    const account = this.#activated?.account;
    this.#walletClient = createWalletClient({
      account: account as Address,
      chain: chain,
      transport: custom(this.#activated?.provider),
    });
  }

  async getProvider() {
    if (!this.#provider) {
      await this._setFc(this.chains[0].id);
    }
    return this.#provider as ReturnType<
      typeof createPublicClient<HttpTransport, Chain>
    >;
  }

  async connect(config?: { chainId?: number }) {
    if ((await this.#fc?.getChainId()) !== config?.chainId) {
      await this._setFc(config?.chainId || 1);
      this.#activated = await (this.#fc as FC).activate();
    }

    return {
      account: this.#activated?.account as Address,
      chain: {
        id: this.#activated?.chainId as number,
        unsupported: false,
      },
    };
  }

  async disconnect(): Promise<void> {
    await this.#fc?.close();
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async getAccount(): Promise<Address> {
    return this.#activated?.account as Address;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async getChainId(): Promise<number> {
    return this.#activated?.chainId as number;
  }

  async getWalletClient(config?: { chainId?: number }) {
    if (
      !!config &&
      !!config.chainId &&
      !!this.#walletClient &&
      config.chainId !== this.#walletClient?.chain.id
    ) {
      await this._setFc(config.chainId);
    }
    return this.#walletClient as ReturnType<
      typeof createWalletClient<CustomTransport, Chain, Address>
    >;
  }

  isAuthorized(): Promise<boolean> {
    if (!this.#walletClient?.account) return Promise.resolve(false);
    return Promise.resolve(true);
  }

  async switchChain?(chainId: number): Promise<Chain> {
    const chain = this.chains.find((chain) => chain.id === chainId);
    if (!chain)
      throw new SwitchChainError(new Error('chain not found on connector.'));

    try {
      const provider = await this.getWalletClient();
      await provider.switchChain({ id: chainId });
      await this._setFc(chainId);
      return chain;
    } catch (error) {
      const message =
        typeof error === 'string'
          ? error
          : (error as ProviderRpcError)?.message;
      if (/user rejected request/i.test(message)) {
        throw new UserRejectedRequestError(error as Error);
      }
      throw new SwitchChainError(error as Error);
    }
  }

  protected onAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) this.emit('disconnect');
    else this.emit('change', { account: getAddress(accounts[0] || '') });
  };

  protected onChainChanged = (chainId: number | string) => {
    const id = Number(chainId);
    const unsupported = this.isChainUnsupported(id);
    this.emit('change', { chain: { id, unsupported } });
  };

  protected onDisconnect = () => {
    this.emit('disconnect');
  };
}
