import { Nullable } from 'common/models/optional';
import Swal from 'sweetalert2';

export namespace SwalUtils {
  type SwalTheme = 'dark' | 'default';

  export const ToastExecutingTx = Swal.mixin({
    icon: 'info',
    toast: true,
    backdrop: false,
    position: 'top-end',
    showConfirmButton: false,
    willOpen: () => {
      SwalUtils.enableSweetAlert2Theme(SwalUtils.getSweetAlertTheme());
      Swal.showLoading();
    },
    showCloseButton: true,
  });

  export const ToastSuccessTx = Swal.mixin({
    icon: 'success',
    toast: true,
    backdrop: false,
    position: 'top-end',
    showConfirmButton: false,
    timer: 10000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    willOpen: () =>
      SwalUtils.enableSweetAlert2Theme(SwalUtils.getSweetAlertTheme()),
    showCloseButton: true,
  });

  export const ToastErrorTx = Swal.mixin({
    icon: 'error',
    toast: true,
    backdrop: false,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    willOpen: () =>
      SwalUtils.enableSweetAlert2Theme(SwalUtils.getSweetAlertTheme()),
    showCloseButton: true,
  });

  export const GenericToastError = Swal.mixin({
    icon: 'error',
    toast: true,
    backdrop: false,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    willOpen: () =>
      SwalUtils.enableSweetAlert2Theme(SwalUtils.getSweetAlertTheme()),
    showCloseButton: true,
  });

  export const enableSweetAlert2Theme = (theme: SwalTheme) => {
    if (typeof window !== 'undefined') {
      const list = document.head.getElementsByTagName('link');
      let swalNode: Nullable<Node> = null;
      let isSwalDarkTheme = false;

      for (let i = 0; i < list.length; i++) {
        if (list[i].href?.includes('sweetalert2')) {
          swalNode = list[i];

          if (list[i].href?.includes('theme-dark')) {
            isSwalDarkTheme = true;
          }
        }
      }

      if (swalNode && theme === 'dark' && !isSwalDarkTheme) {
        // Remove existing node
        document.head.removeChild(swalNode);
      }

      // Now just straight up add the right theme
      const link = document.createElement('link');
      link.type = 'text/css';
      link.href = `//cdn.jsdelivr.net/npm/@sweetalert2/theme-${theme}@4/${theme}.css`;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    }
  };

  export const getSweetAlertTheme = (): SwalTheme => {
    if (typeof window !== 'undefined') {
      return document.body.classList.contains('dark-mode') ? 'dark' : 'default';
    }
    return 'default';
  };

  // TODO: Create a template using React here?  For Swal and set the etherscan?  Maybe let's look at that screen from Uniswap as a template.
}
