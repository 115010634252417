/* eslint-disable no-param-reassign */
import {
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_SERVICE,
  BUILD_TS,
  BUILD_ENV,
  GIT_HASH,
} from 'constants/constants';
import { datadogRum } from '@datadog/browser-rum';

// datadogRum.startSessionReplayRecording();

function initializeTelemetry(): boolean {
  if (!DD_APPLICATION_ID) {
    console.warn('Could not configure telemetry, no application id given.');
    return false;
  }

  if (!DD_CLIENT_TOKEN) {
    console.warn('Could not configure telemetry, no client token given.');
    return false;
  }

  if (!DD_SERVICE) {
    console.warn('Could not configure telemetry, no service given.');
    return false;
  }

  datadogRum.init({
    applicationId: DD_APPLICATION_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: DD_SERVICE,
    env: BUILD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    // defaultPrivacyLevel: 'mask-user-input'
    // TODO Add this in
    // allowedTracingOrigins: ["https://api.example.com", /https:\/\/.*\.my-api-domain\.com/]
  });

  datadogRum.setRumGlobalContext({
    gitLongHash: GIT_HASH,
    nodeEnv: process.env.NODE_ENV,
    buildEnv: BUILD_ENV,
    buildTs: BUILD_TS,
  });

  return true;
}

export function initialize(): boolean {
  return initializeTelemetry();
}
