import { Currency, ETHER, WETH } from '@uniswap/sdk';
import Token from 'common/models/MyToken';
import { Nullable } from 'common/models/optional';
import { ChainId } from 'constants/constants';
import { getAddress } from 'viem';
import { chains } from 'containers/WalletProvider/WalletProvider';

export const GAS_SETTINGS = {
  deposit: 170000,
  withdraw: 210000,
  mint: 450000,
  redeem: 150000,
  stake: 360000,
  unstake: 250000,
  approve: 90000,
  claim: 150000,
  vaultDeposit: 400000,
  vaultDepositWithGuard: 450000,
  ethVaultDeposit: 450000,
  ethVaultDepositWithGuard: 500000,
  vaultWithdraw: 440000,
};

export namespace CryptoUtils {
  export const MAX_NUMBER =
    '115792089237316195423570985008687907853269984665640564039457584007913129639935';

  export function calculateGasMargin(value: bigint, chainId: ChainId): bigint {
    let percent = 1000; // 10% by default
    if (chainId === ChainId.Arbitrum) {
      percent = 120000; // 1300% for Arbitrum
    }
    return (value * (10000n + BigInt(percent))) / 10000n;
  }

  export function isAddress(value: any): string | false {
    try {
      return getAddress(value);
    } catch {
      return false;
    }
  }

  export function unwrappedToken(token: Token): Currency {
    if (token.equals(WETH[token.chainId])) return ETHER;
    return token;
  }

  export function isZero(hexNumberString: string) {
    return /^0x0*$/.test(hexNumberString);
  }

  export function getScannerInfo(
    chainId: ChainId,
    data: string,
    type: 'transaction' | 'token' | 'address' | 'block'
  ): Nullable<{ link: string; name?: string }> {
    if (!data) {
      return null;
    }

    const chain = chains.find((chain) => chain.id === chainId);

    const prefix = chain?.blockExplorers?.default.url;
    const name = chain?.blockExplorers?.default.name;

    switch (type) {
      case 'transaction': {
        return {
          link: `${prefix}/tx/${data}`,
          name: name,
        };
      }
      case 'token':
        {
          return {
            link: `${prefix}/token/${data}`,
            name: name,
          };
        }
        getScannerInfo;
      case 'block': {
        return {
          link: `${prefix}/block/${data}`,
          name: name,
        };
      }
      case 'address':
      default: {
        return {
          link: `${prefix}/address/${data}`,
          name: name,
        };
      }
    }
  }

  export function getUniswapLink(
    chainId: ChainId,
    address: string
  ): Nullable<string> {
    if (!address) {
      return null;
    }

    let prefix = 'https://info.uniswap.org/#';
    prefix = chainId === ChainId.Polygon ? prefix + '/polygon' : prefix;

    return `${prefix}/pools/${address.toLocaleLowerCase()}`;
  }

  export function getScanName(chainId: ChainId): string {
    const chain = chains.find((chain) => chain.id === chainId);
    return chain?.blockExplorers?.default.name ?? '';
  }

  // shorten the checksummed version of the input address to have 0x + 4 characters at start and end
  export function shortenAddress(address: string, chars = 4): string {
    const parsed = isAddress(address);
    if (!parsed) {
      throw Error(`Invalid 'address' parameter '${address}'.`);
    }
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(
      42 - chars
    )}`;
  }

  export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  export function isPolygon(chainId: ChainId) {
    return chainId === ChainId.Polygon || chainId === ChainId.Mumbai;
  }

  export function isArbitrum(chainId: ChainId) {
    return chainId === ChainId.Arbitrum || chainId === ChainId.ArbitrumGoerli;
  }
}
