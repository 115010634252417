import { Chain, Wallet } from '@rainbow-me/rainbowkit';
import { walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import IconZengo from 'common/assets/image/ichifarm/zengo.svg';

interface ZenGoWalletOptions {
  projectId: string;
  chains: Chain[];
  version?: '2';
  options?: any;
}

export const zenGoWallet = ({
  chains,
  projectId,
  version,
  options,
}: ZenGoWalletOptions): Wallet => {
  const walletConnect = walletConnectWallet({
    chains,
    projectId,
    options,
    version,
  });

  return {
    ...walletConnect,
    id: 'zen-go',
    name: 'ZenGo',
    iconUrl: IconZengo,
    iconBackground: '#0c2f78',
    downloadUrls: {
      android: 'https://play.google.com/store/apps/details?id=com.zengo.wallet',
      ios: 'https://apps.apple.com/us/app/zengo-crypto-bitcoin-wallet/id1440147115',
      chrome: walletConnect.downloadUrls?.chrome,
      qrCode: 'https://go.zengo.com/uCxL/74480312',
    },
  };
};
