import React, {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react';
import { SwalUtils } from 'utils/swalUtils';
import { useAccount, useNetwork } from 'wagmi';
import { switchNetwork } from '@wagmi/core';
import { ChainId } from 'constants/constants';

type SelectedChain = {
  chainId: number;
  setChainId: (chainId: number) => void;
};

const initialValue = {
  chainId: 56,
  setChainId: () => {},
};

export const ChainContext = createContext<SelectedChain>(initialValue);

const ChainProvider: FC<PropsWithChildren> = ({ children }) => {
  const { chain } = useNetwork();
  const [chainId, setChainId] = useState<number>(
    chain?.id || initialValue.chainId
  );
  const {address: userAccount} = useAccount();

  const APY_ENDPOINT =
    process.env.APY_ENDPOINT || process.env.GATSBY_APY_ENDPOINT;
  const APY_API_KEY =
    process.env.APY_API_KEY || process.env.GATSBY_APY_API_KEY;

  if (!APY_ENDPOINT) {
    throw new Error('To continue set the APY_ENDPOINT environment variable');
  }
  if (!APY_API_KEY) {
    throw new Error(
      'To continue set the APY_API_KEY environment variable'
    );
  }


  useEffect(() => {
    if (chain?.id) {
      setChainId(chain.id);
      if (userAccount){
        if (chain?.id === ChainId.Bsc) {
          const deployerId = chain?.id === ChainId.Bsc ? 'bril_pancakeswap_bsc' : 'bril_pancakeswap_eth';
          fetch(APY_ENDPOINT, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'api-key': APY_API_KEY,
            },
            body: JSON.stringify({
              "query": `{ totalPositions(userIdentifier: "${userAccount}" blueprintId: "${deployerId}"){requestId count} }`,
            })
          })
        };
      }
    }
  }, [chain?.id, setChainId]);

  const setNewChainId = (newChainId: number) => {
    try {
      if (chain?.id) {
        void switchNetwork({
          chainId: newChainId,
        }).catch((e) => {
          console.warn(e);
          void SwalUtils.GenericToastError.fire({
            title: 'Error Switching Chains',
            text: 'This wallet might not support switching networks.',
          });
        });
      } else {
        setChainId(newChainId);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <ChainContext.Provider
      value={{
        chainId,
        setChainId: setNewChainId,
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};

export default ChainProvider;
