import { initialize } from './src/init';
import Contexts from './src/context/Contexts';
import React from 'react';
export const onClientEntry = () => {
  initialize();
};

export const wrapRootElement = ({ element }) => <Contexts>{element}</Contexts>;

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};
