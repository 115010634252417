import { Colors } from 'common/models/theme';

const lightColors: Colors = {
  transparent: 'transparent',
  primaryTextColor: '#000000',
  warningTextColor: '#FFA043',
  spinnerColor: '#0676FF',
  black: '#000000',
  white: '#ffffff',
  headingColor: '#00387C',
  selectedColor: '#FC6CFF',
  unselectedColor: '#d8d8d8',
  linkHoverColor: '#8c8c8c',
  textColor: '#43414e',
  subTextColor: '#666666',
  sidebarTextColor: '#000000',
  sidebarTextSelectedColor: '#333333',
  sidebarItemSelectedColor: '#0676FF',
  labelColor: '#767676',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  primary: 'rgb(0, 189, 123)',
  primaryDisabled: '#CCF2E5',
  primaryHover: '#2aa275',
  secondary: '#edcd37',
  secondaryHover: '#edcd37',
  yellow: '#fdb32a',
  yellowHover: '#F29E02',

  // Wallet
  walletButtonBgColor: '#CCF2E5',
  // General
  primaryButtonBgColor: '#FC6CFF',
  primaryButtonDisabledBgColor: '#999999',
  primaryButtonColor: '#ffffff',
  secondaryButtonBgColor: '#e8f8f3',
  footerBgColor: '#CCF2E5',
  hrBgColor: '#999999',
  // borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(42, 162, 117, 0.57)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(237, 205, 55, 0.5)',
  sidebarBackgroundColor: '#FDC2FF',
  contentBgColor: '#f1f1f1',
  contentInnerBgColor: '#EDF5FF',
  contentInnerInnerBgColor: '#c5c5c5',
  annoncementBgColor: '#EDF5FF',
  backgroundColor: '#00DBFF',
  borderColor: '#C1DCFE',
  hamburgerIcon: '#046FF3',
  // Tabs
  activeTabColor: '#000000',
  inactiveTabColor: '#999999',
  btnViewAllColor: '#0676ff',
  // Treasury
  treasuryPositionBorder: '1px solid #CCCCCC',
  treasuryPositionBgColor: '#ffffff',
  treasuryPositionNameColor: '#666666',
  treasuryTableSubTextTwoColor: '#00BD7B',
  // Farm & Vault
  farmDashesColor: '#999999',
  noRewardsButtonColor: '#EDF5FF',
  vaultBannerBorderColor: '#A0C8FE',
  // General Input
  inputBorder: '1px solid #DBDBDB',
  inputBorderColor: '#DBDBDB',
  inputLabelColor: '#00387C',
  inputBgColor: '#ffffff',
  inputPlaceholderColor: '#666666',
  // General Table
  tableBorderBottom: '1.1px solid #e8e8e8',
  // Ichi Balance
  ichiBalanceButtonBgColor: '#000000',
  ichiBalanceBgColor: '#ffffff',
  ichiBalanceHeaderBorderBottom: '1px solid #DBDBDB',
  ichiBalanceHeaderTextColor: '#333333',
  ichiBalancePrimaryTextColor: '#ffffff',
  // Modal
  modalBgColor: '#ffffff',
  // redeem
  innerBoxBgColor: '#ffffff',
  // select oneToken
  selectStablecoinBgColor: '#EDF5FF',
  // Wallet
  walletPopperHeaderBorderBottom: '1px solid #DBDBDB',
  walletPopperHeaderTextColor: '#333333',
  walletPopperBgColor: '#DBDBDB',
  walletPopperTextColor: '#DBDBDB',
};

export default lightColors;
