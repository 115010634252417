import { Currency, Token } from '@uniswap/sdk';
import { ChainId } from 'constants/constants';
import { Address } from 'viem';

export default class MyToken extends Currency {
  readonly chainId: ChainId;

  readonly address: Address;

  constructor(
    chainId: ChainId,
    address: Address,
    decimals: number,
    symbol?: string,
    name?: string
  ) {
    super(decimals, symbol, name);
    this.chainId = chainId;
    this.address = address;
  }

  equals(newToken: Token | MyToken): boolean {
    return (
      this.chainId === newToken.chainId && this.address === newToken.address
    );
  }
}
