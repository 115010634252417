// import { BUILD_TS, GIT_HASH } from 'constants/constants';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import { ClaimEvent } from 'xstate/claimEvent';
import { FarmDepositEvent } from 'xstate/farmDepositEvent';
import { VaultModalEvent } from 'xstate/vaultModalEvent';
import { MintEvent } from 'xstate/mintEvent';
import { RedeemEvent } from 'xstate/redeemEvent';
import { StakeEvent } from 'xstate/stakeEvent';
import { UnstakeEvent } from 'xstate/unstakeEvent';
import { VaultDepositEvent } from 'xstate/vaultDepositEvent';
import { FarmWithdrawEvent } from 'xstate/farmWithdrawEvent';
import { datadogRum } from '@datadog/browser-rum';
import { CryptoUtils } from 'utils/cryptoUtils';
import { ChainId } from 'constants/constants';
import { StorageUtils } from 'utils/storageUtils';
import { IchiSwapEvent } from 'xstate/ichiSwapEvents';
import { Nullable } from './optional';

// https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/?tab=npm
export namespace IchiInsights {
  export enum PageView {
    MINT = 'Mint',
    FARM = 'Farm',
  }

  export namespace Events {
    export enum Wallet {
      CLEAR_TRANSACTION = 'ClearTransaction',
    }
  }

  export const DataDogActions = {
    Farm: {
      OpenDepositModal: 'Farm Open Deposit Modal',
      OpenWithdrawModal: 'Farm Open Withdraw Modal',
    },
    Polygon: {
      OpenExternalPolygonBridge: 'Open External Polygon Bridge',
      OpenExternalPolygonBridgeDocs: 'Open External Polygon Bridge Docs',
      CopyIchiAddressForPolygonBridge: 'Copy ICHI address for Polygon Bridge',
    },
    Mint: {
      SelectToken: (symbol: string) => `Select ${symbol} to Mint`,
    },
    Redeem: {
      SelectToken: (symbol: string) => `Select ${symbol} to Redeem`,
    },
  };

  export type CombinedEvents =
    | Events.Wallet
    | MintEvent
    | RedeemEvent
    | StakeEvent
    | UnstakeEvent
    | ClaimEvent
    | VaultModalEvent
    | FarmDepositEvent
    | VaultDepositEvent
    | FarmWithdrawEvent
    | IchiSwapEvent;

  type TrackEvent = {
    event: CombinedEvents;
    properties?: {
      handledAt: string;
      chainId?: ChainId;
      accountAddress?: string;
      storedTx?: Nullable<StorageUtils.StoredTx>;
    } & Record<string, any>;
  };

  export function trackEvent(args: TrackEvent) {
    try {
      const properties = {};
      forEach(keys(args.properties), (key) => {
        const value = args.properties ? args.properties[key] : null;
        properties[key] = isArray(value) ? value.join('\n') : value;
      });

      const accountUrl =
        args.properties?.chainId != null &&
        args.properties?.accountAddress != null
          ? CryptoUtils.getScannerInfo(
              args.properties.chainId,
              args.properties.accountAddress,
              'address'
            )?.link
          : null;
      const txUrl =
        args.properties?.chainId != null &&
        args.properties?.accountAddress != null &&
        args.properties.storedTx != null
          ? CryptoUtils.getScannerInfo(
              args.properties.chainId,
              args.properties.storedTx.hash,
              'transaction'
            )?.link
          : null;

      datadogRum.addAction(args.event, {
        ...properties,
        accountUrl,
        txUrl,
      });
    } catch (e) {
      console.error('Could not log telemetry', e);
    }
  }

  // export function trackPageView(pageView: PageView) {
  //   try {
  //     if (AppInsights) {
  //       AppInsights.trackPageView({
  //         name: pageView
  //       });
  //     }
  //   } catch (e) {
  //     if (AppInsights && isError(e)) {
  //       console.error(`Insights: Could not track page view: ${e.message}`);
  //     }
  //   }
  // }

  export type TrackException = {
    error: Error;
    properties?: {
      handledAt: string;
      chainId?: ChainId;
      accountAddress?: string;
      storedTx?: Nullable<StorageUtils.StoredTx>;
    } & Record<string, any>;
  };
  export function trackException(args: TrackException) {
    try {
      const accountUrl =
        args.properties?.chainId != null &&
        args.properties?.accountAddress != null
          ? CryptoUtils.getScannerInfo(
              args.properties.chainId,
              args.properties.accountAddress,
              'address'
            )?.link
          : null;
      const txUrl =
        args.properties?.chainId != null &&
        args.properties?.accountAddress != null &&
        args.properties.storedTx != null
          ? CryptoUtils.getScannerInfo(
              args.properties.chainId,
              args.properties.storedTx.hash,
              'transaction'
            )?.link
          : null;
      datadogRum.addError(args.error, {
        ...args.properties,
        accountUrl,
        txUrl,
      });
    } catch (e) {
      console.error('Could not log exception telemetry', e);
    }
  }
}
