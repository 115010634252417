import { Chain, Wallet } from '@rainbow-me/rainbowkit';
import { FortmaticConnector } from './FortmaticConnector';
import IconFortmatic from 'common/assets/image/ichifarm/fortmaticIcon.png';
import { Connector } from 'wagmi';

interface FortmaticWalletOptions {
  apiKey: string;
  chains: Chain[];
}

export const fortmaticWallet = ({
  chains,
  apiKey,
}: FortmaticWalletOptions): Wallet => ({
  id: 'fortmatic',
  name: 'Fortmatic Wallet',
  iconUrl: IconFortmatic,
  iconBackground: '#FFFFFF',
  createConnector: () => {
    const connector = new FortmaticConnector({
      options: { apiKey },
      chains,
    });

    return {
      connector: connector as unknown as Connector,
    };
  },
});
